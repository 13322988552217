import { getCustomCheckoutData, getStore, getStores } from "database/firebase"
import { sluggedName } from "lib/utils"
import React from "react"
import getStoreNameCache from "lib/getStoreNameCache"
import createStoreDataCache from "lib/createStoreDataCache"
import StoreContainerElement from "components/store/store-container-element"

export default function SiteIndex({ storeData }) {
  if (!storeData) return null
  const storeDetails = JSON.parse(storeData)

  return (
    <StoreContainerElement storeDetails={storeDetails} isCustomOrSubdomain />
  )
}

export const getStaticPaths = async () => {
  // Get all custom domains
  const stores = await getStores()
  const subdomains = stores.map(
    store => store.storeSlug ?? sluggedName(store.name)
  )
  // const storesWithCustomDomains = stores.filter(store => store.customDomain)
  // const customDomains = storesWithCustomDomains.map(store => store.customDomain)

  const paths = stores
    .filter(store => store.name)
    .filter(store => sluggedName(store.name))
    .map(store => ({
      params: { id: store.id, store: sluggedName(store.name) },
    }))

  const allPaths = [
    ...subdomains.map(subdomain => subdomain),
    // ...customDomains.map(customDomain => customDomain),
  ].filter(path => path)
  // cache the props in a file we can access in getStaticProps
  await createStoreDataCache(paths.map(path => path.params))
  return {
    paths: allPaths.map(path => ({
      params: {
        site: path,
      },
    })),
    fallback: true,
  }
}

//   export const getStaticProps = async ({
//     params,
//   }) => {
//     if (!params) throw new Error("No path parameters found");

//     const { site } = params;

//     let filter: {
//       subdomain?: string;
//       customDomain?: string;
//     } = {
//       subdomain: site,
//     };

//     if (site.includes(".")) {
//       filter = {
//         customDomain: site,
//       };
//     }

//     const data = (await prisma.site.findUnique({
//       where: filter,
//       include: {
//         user: true,
//         posts: {
//           where: {
//             published: true,
//           },
//           orderBy: [
//             {
//               createdAt: "desc",
//             },
//           ],
//         },
//       },
//     })) as _SiteData;

//     if (!data) return { notFound: true, revalidate: 10 };

//     return {
//       props: {
//         stringifiedData: JSON.stringify(data),
//       },
//       revalidate: 3600,
//     };
//   };
export async function getStaticProps({ params }) {
  //Get the store passed from getStaticPaths
  const { site } = params
  //Get the cache of store objects created at build time, these contain our IDs that we can use to fetch page data
  const storeNameCache = await getStoreNameCache()
  // console.log({storeNameCache})
  //Find the object in cache
  const cachedStoreData =
    storeNameCache[storeNameCache.map(obj => obj.store).indexOf(site)]

  //Get page data as normal
  const { id } = cachedStoreData ?? ""
  // const id = "KEz5uurMaOd0Wa3ctlGnUqpqH6P2"
  const storeData = await getStore(id)
  const customCheckoutData = await getCustomCheckoutData(id)
  // If we get checkout data back, we want to remove some fields
  !customCheckoutData.error &&
    delete customCheckoutData.companyName &&
    delete customCheckoutData.companyId
  const serializedData = JSON.stringify({
    ...storeData,
    ...(!customCheckoutData.error &&
      storeData.customCheckoutEnabled &&
      customCheckoutData),
    id,
    storeSlugName: site,
  })
  // Pass post data to the page via props
  return { props: { storeData: serializedData }, revalidate: 3600 }
}
